export function UserInput({
  onChange,
  value,
  id,
  type,
  placeholder,
  full = false,
}) {
  return (
    <div className={`ml-[20px] mb-5 ${full ? "lg:w-100" : "lg:w-96"}`}>
      <div className="font-[Poppins] my-1">{placeholder}</div>
      <input
        type={type}
        className={`font-[Poppins] form-control block px-3 py-1.5 w-[90%] text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none lg:w-[100%]
        }`}
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
}
