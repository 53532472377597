export function HomeCard({ item, onPress }) {
  return (
    <div
      onClick={onPress}
      className="bg-white my-4 self-center max-w-md justify-self-center sm:max-w-[100%] md:max-w-[100%] lg:max-w-[100%]"
      key={item.id}
    >
      <div>
        <img
          className="rounded-lg h-[350px] object-cover"
          src={`${item.images[0]}`}
        />
      </div>
      <div className="px-1 py-2 hover:cursor-pointer">
        <div className="font-[Poppins] text-[16px] text-[#3b3b3b]">
          {item.title}
        </div>
        <div className="font-[Poppins] text-[13px] text-[#878686] pb-2">
          {item.location}
        </div>
        <div className="font-[Poppins] text-[17px] text-[#000] ">
          ₹{item.price}
        </div>
      </div>
    </div>
  );
}
