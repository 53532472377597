import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { Toast } from "./toaster";
import { auth } from "./firebase";
export const SinginUser = async (email, password) => {
  try {
    let response = await signInWithEmailAndPassword(auth, email, password);
    return response;
  } catch (error) {
    Toast(error.message.replace("Firebase:", ""), "error");
    return false;
  }
};

export const SingoutUser = async () => {
  signOut(auth);
};
