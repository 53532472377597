import { Routes, Route, Link } from "react-router-dom";
import { Home, Listing, NotFound, AddListing } from "./screens";
import { AdminLogin, AdminHome } from "../admin";

export function HomePanel() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/listing/:slug" element={<Listing />} />
      <Route path="/admin" element={<AdminLogin />} />
      <Route path="/addlisting" element={<AddListing />} />
      <Route path="/admin/lists" element={<AdminHome />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
