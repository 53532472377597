import {
  collection,
  doc,
  serverTimestamp,
  addDoc,
  getDocs,
  getDoc,
  query,
  where,
  updateDoc,
} from "firebase/firestore";
import {
  ref,
  getDownloadURL,
  uploadBytesResumable,
  getStorage,
  listAll,
} from "firebase/storage";
import { db } from "./firebase";
export const CollectionNames = {
  CM: "ContactMessages",
  LISTINGS: "PropertiesList",
  IF: "IntrestedForm",
};
export const addItem = async (collectionName, items) => {
  try {
    let res = await addDoc(collection(db, collectionName), { ...items });
    if (res && res.path) {
      return res.path;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error, "err at addItem");
    return false;
  }
};

export const UpdateItem = async (Id, approve) => {
  try {
    const docRef = doc(db, CollectionNames["LISTINGS"], Id);
    let res = await updateDoc(docRef, {
      approved: approve,
      updatedAt: serverTimestamp(),
    });
    if (res && res.path) {
      return res.path;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error, "err at addItem");
    return false;
  }
};

export const getListing = async () => {
  try {
    const q = query(
      collection(db, CollectionNames["LISTINGS"]),
      where("approved", "==", true)
    );
    const querySnapshot = await getDocs(q);
    let NewData = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      // console.log(doc.id, " => ", doc.data());
      let newItem = { ...doc.data(), docId: doc.id };
      NewData.push(newItem);
    });
    return NewData;
  } catch (error) {
    console.log("failed to get list", error);
  }
};

export const getSlugListing = async (slug) => {
  try {
    const q = query(
      collection(db, CollectionNames["LISTINGS"]),
      where("slug", "==", slug),
      where("approved", "==", true)
    );
    const querySnapshot = await getDocs(q);
    let NewData = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      // console.log(doc.id, " => ", doc.data());
      let newItem = { ...doc.data(), docId: doc.id };
      NewData.push(newItem);
    });
    if (NewData[0]) {
      let images = await GetAllFiles(NewData[0].id);
      NewData[0].images = images;
      return NewData[0];
    } else {
      return false;
    }
  } catch (error) {
    console.log("failed to get list", error);
    return false;
  }
};

export const getAllContacts = async () => {
  try {
    const q = query(collection(db, CollectionNames["CM"]));
    const querySnapshot = await getDocs(q);
    let NewData = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      // console.log(doc.id, " => ", doc.data());
      let newItem = { ...doc.data(), docId: doc.id };
      NewData.push(newItem);
    });
    return NewData;
  } catch (error) {
    console.log("failed to get list", error);
  }
};
export const getAllIfs = async () => {
  try {
    const q = query(collection(db, CollectionNames["IF"]));
    const querySnapshot = await getDocs(q);
    let NewData = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      // console.log(doc.id, " => ", doc.data());
      let newItem = { ...doc.data(), docId: doc.id };
      NewData.push(newItem);
    });
    return NewData;
  } catch (error) {
    console.log("failed to get list", error);
  }
};
export const getAllListing = async () => {
  try {
    const q = query(collection(db, CollectionNames["LISTINGS"]));
    const querySnapshot = await getDocs(q);
    let NewData = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      // console.log(doc.id, " => ", doc.data());
      let newItem = { ...doc.data(), docId: doc.id };
      NewData.push(newItem);
    });
    return NewData;
  } catch (error) {
    console.log("failed to get list", error);
  }
};
export { serverTimestamp };

export const uploadImages = async (files, uploadId) => {
  try {
    if (Array.isArray(files)) {
      const storage = getStorage();
      for (let i = 0; i < files.length; i++) {
        const imageRef = ref(storage, `${uploadId}/${files[i].name}`);
        let snapshot = await uploadBytesResumable(imageRef, files[i]);
        let downloadUrl = await getDownloadURL(snapshot.ref);
        // console.log("File available at", downloadUrl);
      }
    }
  } catch (err) {
    console.log(err, "error while uploading");
  }
};
export const uploadFile = async (file, uploadId) => {
  try {
    if (file) {
      const storage = getStorage();
      const imageRef = ref(storage, `${uploadId}/docs/${file.name}`);
      let snapshot = await uploadBytesResumable(imageRef, file);
      let downloadUrl = await getDownloadURL(snapshot.ref);
      console.log("File available at", downloadUrl);
    }
  } catch (err) {
    console.log(err, "error while uploading");
  }
};

export const GetAllFiles = async (id) => {
  try {
    const storage = getStorage();
    const listRef = ref(storage, id);
    let refs = await listAll(listRef);
    let urls = [];

    for (const itemRef of refs.items) {
      let url = await getDownloadURL(itemRef);
      urls.push(url);
    }
    return urls;
  } catch (error) {
    console.log(error, "error GetAllFiles");
  }
};

export const GetDocument = async (id) => {
  try {
    const storage = getStorage();
    const listRef = ref(storage, `${id}/docs`);
    let refs = await listAll(listRef);
    let urls = [];

    for (const itemRef of refs.items) {
      let url = await getDownloadURL(itemRef);
      urls.push(url);
    }
    return urls;
  } catch (error) {
    console.log(error, "error GetAllFiles");
  }
};
