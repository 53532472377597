import { useState, useEffect } from "react";
import { UserHeader, HomeCard, Loading } from "../../../components";
import { useNavigate } from "react-router-dom";
import { MdLandscape, MdApartment } from "react-icons/md";
import { HiOutlinePlus } from "react-icons/hi";
import { getListing, GetAllFiles } from "../../../utils/db";
export function Home() {
  const [HomeList, SetHomeLists] = useState([]);
  const [infoLoaded, setinfoLoaded] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    LoadList();
    return () => {};
  }, []);

  const LoadList = async () => {
    let res = await getListing();
    for (let i = 0; i < res.length; i++) {
      let list = await GetAllFiles(res[i].id);
      res[i].images = list;
    }
    setinfoLoaded(true);
    SetHomeLists(res);
  };
  return (
    <div>
      <UserHeader
        onSearch={(txt) => {
          console.log(txt);
        }}
        onLogo={() => {}}
      />
      <div className="flex justify-center">
        <div className="px-4 py-2 items-center mt-40 w-[100%] z-[1] md:mt-24 lg:w-[1040px] lg:mt-24">
          <div className="flex h-[50px] justify-between">
            <div className="flex">
              <div className="flex items-center justify-center rounded border border-slate-300 px-4 h-[40px] mx-1 hover:cursor-pointer">
                <MdLandscape className={"text-[16px] md:ext-[18px]"} />
                <div className="font-[Poppins] text-[12px] text-[#000] px-1 md:text-[14px]">
                  {"Land"}
                </div>
              </div>
              <div className="flex items-center justify-center rounded border border-slate-300 px-4 h-[40px] mx-1 hover:cursor-pointer">
                <MdApartment className={"text-[16px] md:ext-[18px]"} />
                <div className="font-[Poppins] text-[12px] text-[#000] px-1 md:text-[14px]">
                  {"House"}
                </div>
              </div>
            </div>
            <div
              onClick={() => {
                navigate("/addlisting");
              }}
              className="flex items-center justify-center rounded border border-slate-300 px-4 h-[40px] mx-1 hover:cursor-pointer"
            >
              <HiOutlinePlus className={"text-[16px] md:ext-[18px]"} />
              <div className="font-[Poppins] text-[12px] text-[#000] px-1 md:text-[14px]">
                {"List Property"}
              </div>
            </div>
          </div>
          <div className="bg-gradient-to-r from-[#ebe9e8] to-[#f5f5f5] mt-2 px-4 py-6 rounded-lg">
            <div className="font-[PoppinsBold] text-[#212121] text-[24px] underline underline-offset-2 pb-2">
              {"Mana Consultancy"}
            </div>
            <div className="capitalize text-[14px] font-[Poppins]">
              {
                "We provide better solution For sale, purchase, rental, lease, legal, and other services,"
              }
            </div>
          </div>
          {infoLoaded ? (
            <div className="grid grid-cols-1 gap-4 px-4 py-2 w-[100%] z-[1] sm:grid-cols-2 md:grid-cols-2 lg:w-[1000px] lg:grid-cols-2">
              {HomeList.map((item) => {
                return (
                  <HomeCard
                    item={item}
                    onPress={() => {
                      navigate(`/listing/${item.slug}`, { state: { ...item } });
                    }}
                  />
                );
              })}
            </div>
          ) : (
            <div className="mt-20 flex items-center justify-center">
              <Loading />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const Lists = [
  {
    mainImage: "7",
    name: "3 Story 5 bedroom apartment",
    location: "Kondapur, Hyderabad",
    pricing: "15,499,00",
    verified: true,
  },
  {
    mainImage: "11",
    name: "3 acres plot",
    location: "PVSR, Hyderabad",
    pricing: "50,256,00",
    verified: true,
  },
  {
    mainImage: "15",
    name: "Villa with 3 car parking",
    location: "himayat nagar, Hyderabad",
    pricing: "3,50,190,00",
    verified: true,
  },
  {
    mainImage: "17",
    name: "Villa with 3 car parking",
    location: "himayat nagar, Hyderabad",
    pricing: "39,000,00",
    verified: true,
  },
  {
    mainImage: "18",
    name: "Villa with 3 car parking",
    location: "himayat nagar, Hyderabad",
    pricing: "25,000,00",
    verified: true,
  },
];
