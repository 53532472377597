import ReactModal from "react-modal";
export function UserModal({ isModalOpen, onClose, children }) {
  return (
    <ReactModal
      isOpen={isModalOpen}
      appElement={document.getElementById("root")}
      style={{
        overlay: {
          zIndex: 101,
          backgroundColor: "rgba(0,0,0,0.5)",
        },
      }}
      className={
        "absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
      }
      onRequestClose={onClose}
    >
      {children}
    </ReactModal>
  );
}
