import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth,onAuthStateChanged } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAf_2ZtyMkQp31s0DCYP4blLwdAyQ-2Pl8",
  authDomain: "manaconsultancy-c46a7.firebaseapp.com",
  projectId: "manaconsultancy-c46a7",
  storageBucket: "manaconsultancy-c46a7.appspot.com",
  messagingSenderId: "996930057387",
  appId: "1:996930057387:web:4ef9e08f474854689233ee",
  measurementId: "G-QHB5EJ0DVS",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);
