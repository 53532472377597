import { useEffect, useState, useContext } from "react";

import { useNavigate } from "react-router-dom";
import { SingoutUser } from "../../utils/auth";
import { AuthContext } from "../../context/AuthContext";
import { FaFileDownload } from "react-icons/fa";
import moment from "moment";
import {
  getAllListing,
  GetAllFiles,
  UpdateItem,
  getAllContacts,
  getAllIfs,
  GetDocument,
} from "../../utils/db";

let Tabs = ["list", "interested", "contacts"];
export function AdminHome() {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [activeTab, setactiveTab] = useState("list");
  const [allListing, setallListing] = useState([]);
  const [allContactmsgs, setallContactmsgs] = useState([]);
  const [allInterestedForms, setallInterestedForms] = useState([]);
  if (!user) {
    navigate("/admin", { replace: true });
  }
  useEffect(() => {
    LoadList();
  }, []);

  const LoadList = async () => {
    let res = await getAllListing();
    for (let i = 0; i < res.length; i++) {
      let list = await GetAllFiles(res[i].id);
      let document = await GetDocument(res[i].id);
      res[i].images = list;
      res[i].doc = document;
    }
    // console.log(res, res[0].images);
    setallListing(res);
  };

  const Removeapprove = async (id, approve) => {
    await UpdateItem(id, approve);
    setallListing([]);
    LoadList();
  };
  const GetAllContactMsgs = async () => {
    let res = await getAllContacts();
    setallContactmsgs(res);
  };
  const GetAllInterestedForms = async () => {
    let res = await getAllIfs();
    setallInterestedForms(res);
  };
  const ShowContacts = () => {
    return (
      <div className="mx-10 mt-4">
        <div className="flex items-center justify-center h-[50px] border border-slate-200 rounded mb-5">
          <div className="font-[PoppinsBold] text-[#212121] text-lg underline underline-offset-2">
            {activeTab}
          </div>
        </div>
        <div className="flex" style={{ flexWrap: "wrap" }}>
          {allContactmsgs.map((item, index) => {
            let Createdata = new Date(item.createdAt.seconds * 1000);
            return (
              <div className="w-[300px]  border drop-shadow mx-2 my-2">
                <div className="p-2">
                  <span className="font-[Poppins] text-[11px] text-[#878686]">
                    Name:
                  </span>
                  <div className="font-[Poppins] text-[16px] text-[#3b3b3b]">
                    {item.name}
                  </div>
                  <span className="font-[Poppins] text-[11px] text-[#878686]">
                    Email:
                  </span>
                  <div className="font-[Poppins] text-[16px] text-[#3b3b3b]">
                    {item.email}
                  </div>
                  <span className="font-[Poppins] text-[11px] text-[#878686]">
                    Phone:
                  </span>
                  <div className="font-[Poppins] text-[16px] text-[#3b3b3b] ">
                    {item.phone}
                  </div>
                  <span className="font-[Poppins] text-[11px] text-[#878686]">
                    message:
                  </span>
                  <div className="font-[Poppins] text-[16px] text-[#3b3b3b] ">
                    {item.message}
                  </div>
                  <div>
                    {moment(Createdata).format("MMMM Do YYYY, h:mm:ss a")}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  const ShowIntrested = () => {
    return (
      <div className="mx-10 mt-4">
        <div className="flex items-center justify-center h-[50px] border border-slate-200 rounded mb-5">
          <div className="font-[PoppinsBold] text-[#212121] text-lg underline underline-offset-2">
            {activeTab}
          </div>
        </div>
        <div className="flex" style={{ flexWrap: "wrap" }}>
          {allInterestedForms.map((item, index) => {
            let Createdata = new Date(item.createdAt.seconds * 1000);
            return (
              <div className="w-[300px]  border drop-shadow mx-2 my-2">
                <div className="p-2">
                  <span className="font-[Poppins] text-[11px] text-[#878686]">
                    Name:
                  </span>
                  <div className="font-[Poppins] text-[16px] text-[#3b3b3b]">
                    {item.name}
                  </div>
                  <span className="font-[Poppins] text-[11px] text-[#878686]">
                    Email:
                  </span>
                  <div className="font-[Poppins] text-[16px] text-[#3b3b3b]">
                    {item.email}
                  </div>
                  <span className="font-[Poppins] text-[11px] text-[#878686]">
                    Phone:
                  </span>
                  <div className="font-[Poppins] text-[16px] text-[#3b3b3b] ">
                    {item.phone}
                  </div>
                  <span className="font-[Poppins] text-[11px] text-[#878686]">
                    Intrested IN:
                  </span>
                  <div className="font-[Poppins] text-[16px] text-[#3b3b3b] ">
                    {item.linkedTitle}
                  </div>
                  <div>
                    {moment(Createdata).format("MMMM Do YYYY, h:mm:ss a")}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  const ShowList = () => {
    return (
      <div className="mx-10 mt-4">
        <div className="flex items-center justify-center h-[50px] border border-slate-200 rounded mb-5">
          <div className="font-[PoppinsBold] text-[#212121] text-lg underline underline-offset-2">
            {activeTab}
          </div>
        </div>
        <div className="flex" style={{ flexWrap: "wrap" }}>
          {allListing.map((item, index) => {
            return (
              <div className="w-[300px] h-[300px] border drop-shadow my-2 mx-2">
                <div className="h-[150px] overflow-hidden">
                  <img
                    className="rounded-lg w-[100%] object-cover"
                    src={`${item.images[0]}`}
                  />
                </div>
                <div className="p-2">
                  <div className="font-[Poppins] text-[16px] text-[#3b3b3b]">
                    {item.title}
                  </div>
                  <div className="font-[Poppins] text-[13px] text-[#878686] pb-2">
                    {item.location}
                  </div>
                  <div className="flex font-[Poppins] text-[17px] text-[#000] justify-between">
                    <div>₹{item.price}</div>
                    {item.doc ? (
                      <div
                        onClick={(e) => {
                          if (item.doc[0]) {
                            window.open(item.doc[0], "_blank");
                          }
                        }}
                        className="hover:cursor-pointer px-2"
                      >
                        <FaFileDownload
                          className={"text-[16px] md:ext-[18px] text-blue-700"}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="flex">
                  <div
                    onClick={(e) => {
                      navigate(`/listing/${item.slug}`, { state: { ...item } });
                    }}
                    className="flex my-2 mx-2 flex-1 bg-blue-700 items-center justify-center hover:bg-blue-800 px-7 py-1 rounded-lg hover:cursor-pointer"
                  >
                    <span className="font-[Poppins] text-sm text-[#fff]">
                      {"Preview"}
                    </span>
                  </div>
                  <div
                    onClick={(e) => {
                      Removeapprove(
                        item.docId,
                        item.approved == true ? false : true
                      );
                    }}
                    className="flex my-2 mx-2 flex-1 bg-blue-700 items-center justify-center hover:bg-blue-800 px-7 py-1 rounded-lg hover:cursor-pointer"
                  >
                    <span className="font-[Poppins] text-sm text-[#fff]">
                      {item.approved == true ? "remove" : "Approve"}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  return (
    <div>
      <div className="w-[100%] h-[60px] flex justify-between bg-[#fff] drop-shadow">
        <div className="flex items-center justify-center">
          <div className="flex mx-4  font-[Poppins]">{"ADMIN"}</div>
          <div className="flex mt-2 mb-4" style={{ flexWrap: "wrap" }}>
            {Tabs.map((item, index) => {
              return (
                <div
                  onClick={(e) => {
                    setactiveTab(item);
                    if (item == "contacts") {
                      GetAllContactMsgs();
                    }
                    if (item == "interested") {
                      GetAllInterestedForms();
                    }
                  }}
                  key={"listItem" + index}
                  className={`flex items-center justify-center rounded ${
                    activeTab == item ? "border-2" : "border"
                  } border-${activeTab == item ? "slate" : "indigo"}-${
                    activeTab == item ? "300" : "500"
                  } px-4 py-1 mx-1 my-1`}
                >
                  <div className="font-[Poppins]">{item}</div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="flex">
          <div
            onClick={(e) => {
              SingoutUser();
            }}
            className="flex my-2 mx-2 flex-1 bg-blue-700 items-center hover:bg-blue-800 px-7 py-1 rounded-lg hover:cursor-pointer"
          >
            <span className="font-[Poppins] text-sm text-[#fff]">
              {"Logout"}
            </span>
          </div>
        </div>
      </div>

      {activeTab == "list" && ShowList()}
      {activeTab == "contacts" && ShowContacts()}
      {activeTab == "interested" && ShowIntrested()}
    </div>
  );
}
