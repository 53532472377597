import { UserHeader, UserInput } from "../../../components";
import { useNavigate } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { Toast } from "../../../utils/toaster";
import { MdLandscape, MdApartment } from "react-icons/md";
import {
  uploadImages,
  uploadFile,
  addItem,
  CollectionNames,
  serverTimestamp,
} from "../../../utils/db";
import { validateEmail, validatePhone, isNumeric } from "../../../utils/common";
import { v4 as uuidv4 } from "uuid";

const propertyTypeOptions = ["land", "plot", "house", "apartment"];

export function AddListing() {
  const navigate = useNavigate();
  const dropImages = useRef(null);
  const ImagesSetRef = useRef(null);
  const FileSetRef = useRef(null);
  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const [ListDetails, SetListDetails] = useState({
    name: "",
    phone: "",
    email: "",
    checked: true,
  });
  const [PropertyDetailInfo, SetPropertyDetailsInfo] = useState({
    title: "",
    price: "",
    location: "",
    Desc: "",
    listItemText: "",
    list: ["24hrs power and water", "Lift"],
    propertyType: propertyTypeOptions[0],
  });
  const [PropertyImagesSet, SetPropertyImagesSet] = useState(null);
  const [PropertyImagesfile, SetPropertyImagesfile] = useState(null);
  const [RequestLoading, SetRequestLoading] = useState(false);

  const SubmitPropertyForm = async () => {
    try {
      if (RequestLoading) {
        return;
      }
      if (
        !ListDetails.name ||
        (ListDetails.name && ListDetails.name.length < 3)
      ) {
        Toast("Enter a valid name", "error");
        return;
      }
      if (!validatePhone(ListDetails.phone)) {
        Toast("Enter a valid phone number", "error");
        return;
      }
      if (!validateEmail(ListDetails.email)) {
        Toast("Enter a valid email", "error");
        return;
      }
      if (
        !PropertyDetailInfo.title ||
        (PropertyDetailInfo.title && PropertyDetailInfo.title.length < 10)
      ) {
        Toast("Enter a valid title, title must be atleast 10 letters", "error");
        return;
      }

      if (
        !PropertyDetailInfo.price ||
        (PropertyDetailInfo.price &&
          PropertyDetailInfo.price.length < 5 &&
          isNumeric(PropertyDetailInfo.price))
      ) {
        Toast("Enter a valid price, price must be atleast 10,000", "error");
        return;
      }

      if (
        !PropertyDetailInfo.location ||
        (PropertyDetailInfo.location && PropertyDetailInfo.location.length < 5)
      ) {
        Toast(
          "Enter a valid location summary, price must be atleast 10,000",
          "error"
        );
        return;
      }

      if (
        !PropertyDetailInfo.Desc ||
        (PropertyDetailInfo.Desc && PropertyDetailInfo.Desc.length < 30)
      ) {
        Toast(
          "Enter a valid Description, Description must be atleast 30 letters",
          "error"
        );
        return;
      }

      if (!ListDetails.checked) {
        Toast(
          "Please check the commission agreement before submitting ",
          "error"
        );
        return;
      }

      if (
        !PropertyDetailInfo.list ||
        (PropertyDetailInfo.list &&
          Array.isArray(PropertyDetailInfo.list) &&
          PropertyDetailInfo.list.length < 3)
      ) {
        Toast("Add atleast 3 Amenities", "error");
        return;
      }
      let FormId = uuidv4();
      if (Array.isArray(PropertyImagesSet) && PropertyImagesSet.length > 4) {
        if (PropertyImagesSet.length > 15) {
          Toast(
            "Reached images limit please upload 15 or less images",
            "error"
          );
          return;
        }
        SetRequestLoading(true);
        let Files = [];
        PropertyImagesSet.forEach((item) => {
          Files.push(item.file);
        });
        Toast("Started uploading images", "info");
        await uploadImages(Files, FormId);
      } else {
        Toast("Add atleast 5 cover images", "error");
        return;
      }

      if (PropertyImagesfile && PropertyImagesfile.file) {
        Toast("Started uploading file", "info");
        await uploadFile(PropertyImagesfile.file, FormId);
      }
      const location = await fetch("https://geolocation-db.com/json/");
      const locresp = await location.json();

      let res = await addItem(CollectionNames["LISTINGS"], {
        id: FormId,
        userlocationIp: locresp,
        title: PropertyDetailInfo.title,
        slug: PropertyDetailInfo.title.replace(/ /g, "-") + "-" + makeid(5),
        price: PropertyDetailInfo.price,
        location: PropertyDetailInfo.location,
        description: PropertyDetailInfo.Desc,
        amenities: PropertyDetailInfo.list.join(","),
        ownerName: ListDetails.name,
        ownerEmail: ListDetails.email,
        ownerPhone: ListDetails.phone,
        approved: false,
        approvedBy: "z1@3bc76^*93hdf",
        verified: false,
        propertyType: PropertyDetailInfo.propertyType,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });
      if (res) {
        SetRequestLoading(false);
        Toast(
          "Successfully uploaded your listing. verification under process."
        );
        Toast("We will get back to you soon(usually 2-3 wokring days)");
        ResetFields();
      } else {
        SetRequestLoading(false);
      }
    } catch (err) {
      SetRequestLoading(false);
      console.log(err, "err at form upload");
    }
  };

  const ResetFields = () => {
    SetPropertyImagesSet(null);
    SetPropertyImagesfile(null);
    SetListDetails({
      name: "",
      phone: "",
      email: "",
    });
    SetPropertyDetailsInfo({
      title: "",
      price: "",
      location: "",
      Desc: "",
      listItemText: "",
      list: ["24hrs power and water", "Lift"],
    });
    if (ImagesSetRef && ImagesSetRef.current) {
      ImagesSetRef.current.value = "";
    }
    if (FileSetRef && FileSetRef.current) {
      FileSetRef.current.value = "";
    }
  };
  useEffect(() => {
    dropImages.current.addEventListener("dragover", handleDragOver);
    dropImages.current.addEventListener("drop", handleDrop);

    return () => {
      dropImages.current &&
        dropImages.current.removeEventListener("dragover", handleDragOver);
      dropImages.current &&
        dropImages.current.removeEventListener("drop", handleDrop);
    };
  }, []);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { files } = e.dataTransfer;

    if (files && files.length) {
      let images = [];
      for (let i = 0; i < files.length; i++) {
        let filemanager = {
          file: files[i],
          preview: URL.createObjectURL(files[i]),
        };
        images.push(filemanager);
      }
      SetPropertyImagesSet(images);
    }
  };

  const AddTextToList = () => {
    if (PropertyDetailInfo.listItemText.length > 2) {
      let NewItem = [...PropertyDetailInfo.list];
      NewItem.push(PropertyDetailInfo.listItemText);
      SetPropertyDetailsInfo({
        ...PropertyDetailInfo,
        list: NewItem,
        listItemText: "",
      });
    } else {
      Toast(
        "Amenities must have atleast 2 characters (EX: 5km away from airport)",
        "error"
      );
    }
  };

  const PersonalDetails = () => {
    return (
      <div className="mb-10">
        <div className="font-[PoppinsBold] text-[#757373] text-l underline underline-offset-2 mb-4">
          {"Personal Info"}
        </div>
        <div className="flex flex-col lg:flex-row">
          <UserInput
            onChange={(e) => {
              SetListDetails({ ...ListDetails, name: e.target.value });
            }}
            value={ListDetails.name}
            id={"ListName"}
            placeholder={"Name*"}
            type="text"
          />
          <UserInput
            onChange={(e) => {
              SetListDetails({ ...ListDetails, phone: e.target.value });
            }}
            value={ListDetails.phone}
            id={"ListPhone"}
            placeholder={"Phone*"}
            type="text"
          />
        </div>
        <div>
          <UserInput
            onChange={(e) => {
              SetListDetails({ ...ListDetails, email: e.target.value });
            }}
            value={ListDetails.email}
            id={"ListEmail"}
            placeholder={"E-Mail"}
            type="email"
          />
        </div>
      </div>
    );
  };
  const PropertyDetails = () => {
    return (
      <div className="mb-10">
        <div className="font-[PoppinsBold] text-[#757373] text-l underline underline-offset-2 mb-4">
          {"Property Info"}
        </div>
        <div>
          <UserInput
            full
            onChange={(e) => {
              SetPropertyDetailsInfo({
                ...PropertyDetailInfo,
                title: e.target.value,
              });
            }}
            value={PropertyDetailInfo.title}
            id={"ListTitle"}
            placeholder={"Title*"}
            type="text"
          />
        </div>
        <div className="flex flex-col lg:flex-row">
          <UserInput
            onChange={(e) => {
              SetPropertyDetailsInfo({
                ...PropertyDetailInfo,
                price: e.target.value,
              });
            }}
            value={PropertyDetailInfo.price}
            id={"ListPrice"}
            placeholder={"Price*"}
            type="text"
          />
          <UserInput
            onChange={(e) => {
              SetPropertyDetailsInfo({
                ...PropertyDetailInfo,
                location: e.target.value,
              });
            }}
            value={PropertyDetailInfo.location}
            id={"ListLocation"}
            placeholder={"Location Summary*"}
            type="text"
          />
        </div>
        <div>
          <UserInput
            full
            onChange={(e) => {
              SetPropertyDetailsInfo({
                ...PropertyDetailInfo,
                Desc: e.target.value,
              });
            }}
            value={PropertyDetailInfo.Desc}
            id={"ListDesc"}
            placeholder={"Description*"}
            type="text"
          />
        </div>
        <div className="flex flex-col ml-[20px] mb-5">
          <div className="font-[Poppins] my-1">{"Property Type*"}</div>
          <div className="flex">
            {propertyTypeOptions.map((Ptitem) => {
              return (
                <div
                  onClick={(e) => {
                    SetPropertyDetailsInfo({
                      ...PropertyDetailInfo,
                      propertyType: Ptitem,
                    });
                  }}
                  className={`flex items-center justify-center rounded border ${
                    Ptitem === PropertyDetailInfo.propertyType
                      ? "drop-shadow border-solid border-gray-500"
                      : ""
                  } border-slate-300 px-4 h-[40px] mx-1 hover:cursor-pointer`}
                >
                  {Ptitem == "land" || Ptitem == "plot" ? (
                    <MdLandscape className={"text-[16px] md:ext-[18px]"} />
                  ) : (
                    <MdApartment className={"text-[16px] md:ext-[18px]"} />
                  )}
                  <div className="font-[Poppins] text-[12px] text-[#000] px-1 md:text-[14px]">
                    {Ptitem}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex flex-col ml-[20px] mb-5">
          <div className="font-[Poppins] my-1">{"Amenities*"}</div>
          <div className="flex flex-row ">
            <input
              type={"text"}
              className={`font-[Poppins] form-control block px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none w-[80%] lg:w-[100%]
        }`}
              id={"ListAmenities"}
              placeholder={"Amenities*"}
              value={PropertyDetailInfo.listItemText}
              onChange={(e) => {
                SetPropertyDetailsInfo({
                  ...PropertyDetailInfo,
                  listItemText: e.target.value,
                });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  AddTextToList();
                }
              }}
            />
            <div className="flex">
              <div
                onClick={(e) => {
                  AddTextToList();
                }}
                className="flex mx-2 flex-1 bg-blue-700 items-center hover:bg-blue-800 px-7 py-1 rounded-lg hover:cursor-pointer"
              >
                <span className="font-[Poppins] text-sm text-[#fff]">
                  {"Add"}
                </span>
              </div>
            </div>
          </div>
          <div className="flex mt-[10px] " style={{ flexWrap: "wrap" }}>
            {PropertyDetailInfo.list.map((item, index) => {
              return (
                <div
                  key={"listItem" + index}
                  className="flex items-center justify-center rounded border border-slate-300 px-2 py-1 mx-1 my-1 drop-shadow"
                >
                  <div className="pl-2 font-[Poppins]">{item}</div>
                  <div
                    className="pl-2 hover:cursor-pointer"
                    onClick={() => {
                      let newList = [...PropertyDetailInfo.list];
                      newList = newList.filter((newitem) => newitem !== item);
                      SetPropertyDetailsInfo({
                        ...PropertyDetailInfo,
                        list: newList,
                      });
                    }}
                  >
                    <IoMdClose size={"20px"} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };
  const PropertyImages = () => {
    return (
      <div className="mb-10">
        <div className="font-[PoppinsBold] text-[#757373] text-l underline underline-offset-2 mb-4">
          {"Property Images"}
        </div>
        <div>
          <div className="font-[Poppins] my-1">
            {"Upload atleast 5 images*"}
          </div>
          <div className="flex justify-center items-center w-full">
            <label
              for="dropzone-file"
              ref={dropImages}
              className="flex flex-col justify-center items-center w-full h-64 bg-[#F3F4F6] rounded-lg border-2 border-gray-300 border-dashed cursor-pointer hover:bg-[#F3F4F6]  "
            >
              <div className="flex flex-col justify-center items-center pt-5 pb-6">
                <svg
                  aria-hidden="true"
                  className="mb-3 w-10 h-10 text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                  ></path>
                </svg>
                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                  <span className="font-semibold">Click to upload</span> or drag
                  and drop
                </p>
                <p className="text-xs text-gray-500 dark:text-gray-400">
                  PNG, JPG
                </p>
              </div>
              <input
                ref={ImagesSetRef}
                onChange={(e) => {
                  if (!e.target.files || e.target.files.length === 0) {
                    return;
                  }
                  let images = [];
                  for (let i = 0; i < e.target.files.length; i++) {
                    let filemanager = {
                      file: e.target.files[i],
                      preview: URL.createObjectURL(e.target.files[i]),
                    };
                    images.push(filemanager);
                  }
                  SetPropertyImagesSet(images);
                }}
                id="dropzone-file"
                type="file"
                className="hidden"
                multiple
              />
            </label>
          </div>
          <div>
            <div className="flex mt-2 mb-4" style={{ flexWrap: "wrap" }}>
              {PropertyImagesSet &&
                PropertyImagesSet.map((item) => {
                  return (
                    <img
                      className="rounded-lg h-[120px] mx-1 my-2 object-cover"
                      src={item.preview}
                    />
                  );
                })}
            </div>
            {PropertyImagesSet ? (
              <div className="flex justify-end items-end">
                <div
                  className="self-end px-2 py-1 mx-2 mt-2 bg-[#e5e5e5] rounded-lg hover:cursor-pointer"
                  onClick={() => {
                    SetPropertyImagesSet(null);
                    if (ImagesSetRef && ImagesSetRef.current) {
                      ImagesSetRef.current.value = "";
                    }
                  }}
                >
                  <span className="font-[Poppins] text-right text-[#000] text-[14px]">
                    {"Clear Images"}
                  </span>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  const PropertyLegal = () => {
    return (
      <div className="mb-10">
        <div className="font-[PoppinsBold] text-[#757373] text-l underline underline-offset-2 mb-4">
          {"Property legal document (Optional)"}
        </div>
        <div>
          <div className="font-[Poppins] my-1">
            {"Upload PDF of legal document"}
          </div>
          <input
            className="block w-full text-sm font-[Poppins] text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer focus:outline-none"
            id="file_input"
            type="file"
            ref={FileSetRef}
            onChange={(e) => {
              if (!e.target.files || e.target.files.length === 0) {
                return;
              }
              let filemanager = {
                file: e.target.files[0],
                preview: URL.createObjectURL(e.target.files[0]),
              };

              SetPropertyImagesfile(filemanager);
            }}
          />
        </div>
      </div>
    );
  };
  return (
    <div>
      <UserHeader
        onSearch={(txt) => {
          console.log(txt);
        }}
        onLogo={() => {
          navigate("/");
        }}
      />
      <div className="flex justify-center">
        <div className="px-4 py-2 items-center mt-40 w-[100%] z-[1] md:mt-24 lg:w-[840px] lg:mt-24">
          <div className="flex items-center justify-center h-[50px] border border-slate-200 rounded mb-5">
            <div className="font-[PoppinsBold] text-[#212121] text-lg underline underline-offset-2">
              {"List Your Property"}
            </div>
          </div>
          {PersonalDetails()}
          {PropertyDetails()}
          {PropertyImages()}
          {PropertyLegal()}

          <div className="flex">
            <div className="mb-4">
              <input
                type="checkbox"
                className="px-2"
                onChange={(e) => {
                  SetListDetails({ ...ListDetails, checked: e.target.checked });
                }}
                checked={ListDetails.checked}
              />
              <label className="pl-2 font-[PoppinsBold] text-[#757373] text-l underline underline-offset-2 mb-4">
                {
                  "By clicking submit you agree to provide 1% commission if the property is sold by manaconsultancy.in "
                }
              </label>
            </div>
          </div>
          <div className="flex">
            <div
              onClick={(e) => {
                SubmitPropertyForm();
              }}
              className="flex mx-2 flex-1 bg-blue-700 items-center justify-center hover:bg-blue-800 px-7 py-[11px] rounded-lg hover:cursor-pointer"
            >
              <span className="font-[Poppins] text-sm text-[#fff]">
                {"Submit Listing"}
              </span>
            </div>
          </div>

          <div className="h-[200px]" />
        </div>
      </div>
    </div>
  );
}
