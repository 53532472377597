import { useEffect, useState } from "react";
import {
  ImageModal,
  UserHeader,
  UserModal,
  Loading,
} from "../../../components";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { FaUser, FaPhoneAlt, FaMailBulk } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { NotFound } from "../screens/NotFound";
import {
  CollectionNames,
  addItem,
  serverTimestamp,
  getSlugListing,
} from "../../../utils/db";
import { Toast } from "../../../utils/toaster";

export function Listing() {
  let { slug } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [openContactModal, setOpenContactModal] = useState(false);
  const [openGalleryModal, setopenGalleryModal] = useState(false);
  const [InfoLoaded, setInfoLoaded] = useState(state ? true : false);
  const [ListData, SetListData] = useState(state);
  const [IntrestedForm, SetIntrestedForm] = useState({
    name: "",
    email: "",
    phone: "",
  });
  useEffect(() => {
    if (!state) {
      LoadDataFromSlug();
    }
  }, []);
  const LoadDataFromSlug = async () => {
    try {
      let response = await getSlugListing(slug);
      if (response) {
        SetListData(response);
      }
      setInfoLoaded(true);
    } catch (error) {
      setInfoLoaded(true);
      console.log("failed to retrive this property");
    }
  };
  const openGallery = () => {
    setopenGalleryModal(true);
  };
  const UploadIntrestedForm = async (e) => {
    try {
      e.preventDefault();
      if (
        !IntrestedForm.name ||
        (IntrestedForm.name && IntrestedForm.name.length < 3)
      ) {
        Toast("Enter a valid name", "error");
        return;
      }
      if (
        !IntrestedForm.email ||
        (IntrestedForm.email && IntrestedForm.email.length < 5)
      ) {
        Toast("Enter a valid email", "error");
        return;
      }
      if (
        !IntrestedForm.phone ||
        (IntrestedForm.phone && IntrestedForm.phone.length != 10)
      ) {
        Toast("Enter a valid phone number", "error");
        return;
      }

      let res = await addItem(CollectionNames["IF"], {
        name: IntrestedForm.name,
        email: IntrestedForm.email,
        phone: IntrestedForm.phone,
        slug: slug,
        linkedId: ListData.docId,
        linkedTitle: ListData.title,
        createdAt: serverTimestamp(),
      });
      if (res) {
        setOpenContactModal(false);
        SetIntrestedForm({
          name: "",
          email: "",
          phone: "",
          msg: "",
        });
      }
    } catch (error) {
      console.log(error, "err at UploadContactForm");
    }
  };
  return (
    <div>
      <UserHeader
        onSearch={(txt) => {
          console.log(txt);
        }}
        onLogo={() => {
          navigate("/");
        }}
      />
      <div className="flex justify-center">
        {ListData ? (
          <div className="mt-20 mt-40 px-4 py-2 w-[100%] z-[1] md:mt-20 lg:w-[1000px] lg:mt-20">
            <div className="flex ">
              <div className="flex flex-1 pr-2 pb-2">
                <img
                  className="rounded-lg w-[100%] object-cover"
                  src={`${ListData.images[0]}`}
                />
              </div>
              <div className="flex-1 flex-wrap hidden sm:flex">
                <div className="w-[50%] h-[50%] pr-2 pb-2">
                  <img
                    className="rounded-lg h-[100%] object-cover"
                    src={`${ListData.images[1]}`}
                  />
                </div>
                <div className="w-[50%] h-[50%] pr-2 pb-2">
                  <img
                    className="rounded-lg h-[100%] object-cover"
                    src={`${ListData.images[2]}`}
                  />
                </div>
                <div className="w-[50%] h-[50%] pr-2 pb-2">
                  <img
                    className="rounded-lg h-[100%] object-cover"
                    src={`${ListData.images[3]}`}
                  />
                </div>
                <div className="w-[50%] h-[50%] pr-2 pb-2 relative">
                  <img
                    className="rounded-lg h-[100%] object-cover"
                    src={`${ListData.images[4]}`}
                  />
                  <div className="flex justify-end absolute top-0 left-0 right-2 bottom-2 bg-[#00000088] rounded-lg">
                    <div
                      className="self-end px-2 py-1 m-2 bg-[#e5e5e5] rounded-lg hover:cursor-pointer"
                      onClick={() => {
                        openGallery();
                      }}
                    >
                      <span className="font-[Poppins] text-right text-[#000] text-[14px]">
                        {"Show all"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-end sm:hidden">
              <div
                className="self-end px-4 py-1 mx-2 bg-[#e5e5e5] rounded-lg hover:cursor-pointer"
                onClick={() => {
                  openGallery();
                }}
              >
                <span className="font-[Poppins] text-right text-[#000] text-[14px]">
                  {"Show all pics"}
                </span>
              </div>
            </div>

            <div className="flex-col flex sm:flex-row">
              <div className="flex-[2] py-2 mr-2">
                <div className="mb-8 mt-4">
                  <p className="font-[Poppins] font-medium text-2xl text-[#222222]">
                    {ListData.title}
                  </p>
                  <span className="font-[Poppins] text-sm text-[#3a3a3a]">
                    {ListData.location} . ₹{ListData.price}
                  </span>
                </div>
                {/* <div className="h-[1px] m-1 bg-[#ddd]" /> */}
                <div className="flex flex-col">
                  <div>
                    <span className="font-[Poppins] font-semibold text-base text-[#222222]">
                      {"Perks/Amenities"}
                    </span>
                  </div>
                  <div className="flex flex-wrap py-2">
                    {ListData.amenities.split(",").map((item) => {
                      return (
                        <div className="w-[50%]">
                          <div className="flex py-2">
                            <div className="w-[4px] mx-3 h-[4px] bg-[#000] rounded self-center justify-self-center" />
                            <span className="font-[Poppins] capitalize text-sm text-[#3a3a3a]">
                              {item}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="mt-4">
                  <div>
                    <span className="font-[Poppins] font-semibold text-base text-[#222]">
                      {"More Information"}
                    </span>
                  </div>
                  <div className="py-2">
                    <span className="font-[Poppins] text-sm text-[#3a3a3a]">
                      {ListData.description}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-1 flex-col border-[#ccc] max-h-[350px] border p-2 mt-4 rounded-lg sm:mx-2">
                <div className="flex flex-1 flex-col">
                  <span className="font-[Poppins] text-center text-base text-[#222] underline">
                    {"Seller Information"}
                  </span>
                  <div className="flex px-2 mb-3 mt-6">
                    <div className="bg-[#dededecc] px-2 py-2 rounded-lg text-[#666666]">
                      <FaUser size={"14px"} />
                    </div>
                    <div className="flex flex-1 items-center ">
                      <span className="capitalize px-2 text-sm font-[Poppins]">
                        {ListData.ownerName}
                      </span>
                    </div>
                  </div>
                  <div className="flex px-2 my-3">
                    <div className="bg-[#dededecc] px-2 py-2 rounded-lg text-[#666666]">
                      <FaPhoneAlt size={"14px"} />
                    </div>
                    <div className="flex flex-1 items-center blur-sm">
                      <span className="capitalize px-2 text-sm font-[Poppins]">
                        +{Math.floor(Math.random() * 100000000 + 1)} 00
                      </span>
                    </div>
                  </div>
                  <div className="flex px-2 my-3">
                    <div className="bg-[#dededecc] px-2 py-2 rounded-lg text-[#666666]">
                      <FaMailBulk size={"14px"} />
                    </div>
                    <div className="flex flex-1 items-center blur-sm">
                      <span className="capitalize px-2 text-sm font-[Poppins]">
                        randomuser{Math.floor(Math.random() * 1000 + 1)}
                        @listing.com
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-center">
                  <div
                    onClick={() => {
                      setOpenContactModal(true);
                    }}
                    className="bg-blue-700 hover:bg-blue-800 px-4 py-1 rounded-lg my-4 hover:cursor-pointer"
                  >
                    <span className="font-[Poppins] text-sm text-[#fff]">
                      {"Interested"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            {InfoLoaded ? (
              <NotFound />
            ) : (
              <div className="mt-80">
                <Loading />
              </div>
            )}
          </div>
        )}
      </div>
      <UserModal
        isModalOpen={openContactModal}
        onClose={() => {
          setOpenContactModal(false);
        }}
      >
        <div className="bg-[#fff] rounded w-[400px] max-h-[450px]">
          <div
            className="flex px-2 pt-2 items-end justify-end hover:cursor-pointer"
            onClick={() => {
              setOpenContactModal(false);
            }}
          >
            <IoMdClose size={"20px"} />
          </div>
          <div className="flex flex-col justify-center p-[20px]">
            <div className="mb-3 xl:w-96">
              <label
                for="exampleEmail0"
                className="form-label font-[Poppins] inline-block mb-2 text-gray-700"
              >
                Contact Name*
              </label>
              <input
                type="text"
                className="
        form-control
        block
        px-3
        py-1.5
        w-[90%]
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
      "
                id="nameInput"
                onChange={(e) => {
                  SetIntrestedForm({ ...IntrestedForm, name: e.target.value });
                }}
                value={IntrestedForm.name}
                placeholder="Name"
              />
            </div>
            <div className="mb-3 xl:w-96">
              <label
                for="exampleEmail0"
                className="form-label font-[Poppins] inline-block mb-2 text-gray-700"
              >
                Contact Email*
              </label>
              <input
                type="email"
                className="
        form-control
        block
        w-[90%]
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
      "
                id="emailInput"
                placeholder="Email"
                onChange={(e) => {
                  SetIntrestedForm({ ...IntrestedForm, email: e.target.value });
                }}
                value={IntrestedForm.email}
              />
            </div>
            <div className="mb-3 xl:w-96">
              <label
                for="exampleEmail0"
                className="form-label font-[Poppins] inline-block mb-2 text-gray-700"
              >
                Contact Phone*
              </label>
              <input
                type="email"
                className="
        form-control
        block
        w-[90%]
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
      "
                id="phoneInput"
                placeholder="phone"
                onChange={(e) => {
                  SetIntrestedForm({ ...IntrestedForm, phone: e.target.value });
                }}
                value={IntrestedForm.phone}
              />
            </div>
            <span className="font-[Poppins] text-sm text-[#999]">
              {
                "After submitting your request you may receive call or email within 2-3 working days"
              }
            </span>
          </div>

          <div className="flex items-center justify-center">
            <div
              onClick={(e) => {
                UploadIntrestedForm(e);
              }}
              className="bg-blue-700 hover:bg-blue-800 px-5 py-1 rounded-lg mb-[20px] hover:cursor-pointer"
            >
              <span className="font-[Poppins] text-sm text-[#fff]">
                {"Send Request"}
              </span>
            </div>
          </div>
        </div>
      </UserModal>
      <ImageModal
        Images={ListData ? ListData.images : []}
        isModalOpen={openGalleryModal}
        onClose={() => {
          setopenGalleryModal(false);
        }}
      />
    </div>
  );
}

let perks = [
  "Aiport",
  "gym",
  "mall",
  "car parking",
  "temple",
  "ground",
  "walking area 2km",
  "cycling",
];

let listObjet = {
  name: "",
  address: "",
  verified: false,
  imgs: ["images/6.jpg", "images/7.jpg", "images/9.jpg", "images/12.jpg"],
};
