import { UserHeader } from "../../../components";
import { useNavigate } from "react-router-dom";
import { HiArrowCircleRight } from "react-icons/hi";
export function NotFound() {
  const navigate = useNavigate();

  return (
    <div>
      <UserHeader
        onSearch={(txt) => {
          console.log(txt);
        }}
        onLogo={() => {}}
      />
      <div className="flex flex-col mt-60 h-[100%] items-center justify-center">
        <div className="max-w-[400px]">
          <img
            className="object-contain w-[200] h-[100]"
            src={"/images/404.svg"}
          />
        </div>
        <div
          className="flex mt-20 hover:cursor-pointer"
          onClick={() => {
            navigate("/");
          }}
        >
          <div className="font-[PoppinsBold] text-[#212121] text-lg underline underline-offset-2">
            {"Go To Home"}
          </div>
          <div className="flex items-center ml-1">
            <HiArrowCircleRight />
          </div>
        </div>
      </div>
    </div>
  );
}
