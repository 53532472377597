import { useState } from "react";
import { IoMdMail, IoIosCall, IoMdClose } from "react-icons/io";
import { UserModal } from "../UserModal/UserModal";
import { addItem, CollectionNames, serverTimestamp } from "../../utils/db";
import { Toast } from "../../utils/toaster";

export function UserHeader({ onSearch, onLogo }) {
  const [ConatctModal, SetContactModal] = useState(false);
  const [ContactInfo, SetContactInfo] = useState({
    name: "",
    email: "",
    phone: "",
    msg: "",
  });
  const UploadContactForm = async (e) => {
    try {
      e.preventDefault();
      if (
        !ContactInfo.name ||
        (ContactInfo.name && ContactInfo.name.length < 3)
      ) {
        Toast("Enter a valid name", "error");
        return;
      }
      if (
        !ContactInfo.email ||
        (ContactInfo.email && ContactInfo.email.length < 5)
      ) {
        Toast("Enter a valid email", "error");
        return;
      }
      if (
        !ContactInfo.phone ||
        (ContactInfo.phone && ContactInfo.phone.length != 10)
      ) {
        Toast("Enter a valid phone number", "error");
        return;
      }
      if (!ContactInfo.msg || (ContactInfo.msg && ContactInfo.msg.length < 3)) {
        Toast("Enter a valid msg", "error");
        return;
      }
      let res = await addItem(CollectionNames["CM"], {
        name: ContactInfo.name,
        email: ContactInfo.email,
        phone: ContactInfo.phone,
        message: ContactInfo.msg,
        createdAt: serverTimestamp(),
      });
      if (res) {
        SetContactModal(false);
        SetContactInfo({
          name: "",
          email: "",
          phone: "",
          msg: "",
        });
      }
    } catch (error) {
      console.log(error, "err at UploadContactForm");
    }
  };
  const SearchBar = () => {
    return (
      <div className="relative w-[90%]  md:w-[100%] lg:max-w-[1000px]">
        <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none z-[1]">
          <svg
            aria-hidden="true"
            className="w-5 h-5 text-gray-500 dark:text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            ></path>
          </svg>
        </div>
        <input
          type="search"
          id="default-search"
          className="block p-4 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg placeholder-gray-400 font-[Poppins] focus:outline-0 focus:drop-shadow"
          placeholder="Search locality, design..."
          onChange={(e) => onSearch(e.target.value)}
          required
        />
        <button
          type="submit"
          className="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus: font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Search
        </button>
      </div>
    );
  };
  return (
    <div className="fixed top-0 left-0 right-0 z-[100] bg-white h-40  md:h-20 lg:h-20">
      <div className="flex px-4 items-center w-[100%] h-[50%] justify-between md:h-[100%] md:px-1 lg:h-[100%]">
        <div
          className="px-2.5 w-[220px] flex justify-center items-center"
          onClick={onLogo}
        >
          <div className="font-[PoppinsBold] mx-2 text-[#212121] text-[50px]">
            {"M"}
          </div>
          <div className="font-[PoppinsBold] text-[#212121] text-l underline underline-offset-2">
            {"Mana Consultancy"}
          </div>
        </div>
        <div className="flex-1 justify-center hidden md:flex lg:flex">
          <SearchBar />
        </div>
        <div className="p-2.5 w-[180px] flex justify-end md:justify-center">
          <div
            onClick={() => {
              SetContactModal(true);
            }}
            className="w-[80px] h-[35px] bg-[#dededecc] flex justify-center items-center rounded-lg cursor-pointer hover:bg-[#dededeff] hover:text-[#000] text-[#666666]"
          >
            <div className="px-2">
              <IoIosCall size={"18px"} />
            </div>{" "}
            |
            <div className="px-2">
              <IoMdMail size={"18px"} />
            </div>
          </div>
        </div>
      </div>
      <div className="flex-1 justify-center flex md:hidden lg:hidden">
        <SearchBar />
      </div>
      <UserModal
        isModalOpen={ConatctModal}
        onClose={() => {
          SetContactModal(false);
        }}
      >
        <div className="bg-[#fff] rounded w-[400px] max-h-[500px] p-[12px]">
          <div
            className="flex px-2 pt-2 items-end justify-end hover:cursor-pointer"
            onClick={() => {
              SetContactModal(false);
            }}
          >
            <IoMdClose size={"20px"} />
          </div>
          <div className="flex-col flex-1 items-center mb-[25px] mt-[10px] ">
            <div>
              <span className="capitalize px-2 text-lg font-[Poppins]">
                {"Contact Info"}
              </span>
            </div>
            <div>
              <span className="capitalize px-2 text-sm font-[Poppins]">
                {"Yadagiri Rao - 9490015972"}
              </span>
            </div>
          </div>
          <div className="flex flex-1 justify-center my-[20px]">
            <div className="h-[1px] w-[95%] bg-[#555]" />
          </div>
          <div className="flex-col flex-1 items-center ">
            <div className="mb-[15px]">
              <span className="capitalize px-2 text-lg font-[Poppins]">
                {"Contact"}
              </span>
            </div>
            <div className="ml-[8px] mb-5 xl:w-96">
              <input
                type="text"
                className="
        form-control
        block
        px-3
        py-1.5
        w-[90%]
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
      "
                id="nameInput"
                placeholder="Name"
                value={ContactInfo.name}
                onChange={(e) =>
                  SetContactInfo({ ...ContactInfo, name: e.target.value })
                }
              />
            </div>
            <div className="ml-[8px] mb-5 xl:w-96">
              <input
                type="email"
                className="
        form-control
        block
        w-[90%]
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
      "
                id="emailInput"
                placeholder="Email"
                value={ContactInfo.email}
                onChange={(e) =>
                  SetContactInfo({ ...ContactInfo, email: e.target.value })
                }
              />
            </div>
            <div className="ml-[8px] mb-5 xl:w-96">
              <input
                type={"text"}
                className="
        form-control
        block
        w-[90%]
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
      "
                id="phoneInput"
                placeholder="phone"
                value={ContactInfo.phone}
                onChange={(e) =>
                  SetContactInfo({ ...ContactInfo, phone: e.target.value })
                }
              />
            </div>
            <div className="ml-[8px] mb-5 xl:w-96">
              <textarea
                type={"text"}
                className="
        form-control
        block
        w-[90%]
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
      "
                id="messageInput"
                placeholder="Message"
                value={ContactInfo.msg}
                onChange={(e) =>
                  SetContactInfo({ ...ContactInfo, msg: e.target.value })
                }
              />
            </div>
          </div>
          <div className="flex items-center justify-center">
            <div
              onClick={(e) => {
                UploadContactForm(e);
              }}
              className="bg-blue-700 hover:bg-blue-800 px-5 py-1 rounded-lg mb-[20px] hover:cursor-pointer"
            >
              <span className="font-[Poppins] text-sm text-[#fff]">
                {"Send"}
              </span>
            </div>
          </div>
        </div>
      </UserModal>
    </div>
  );
}
