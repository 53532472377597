import { Bars } from "react-loader-spinner";
export function Loading({}) {
  return (
    <div>
      <Bars
        height="80"
        width="80"
        radius="9"
        color="#2663eb"
        ariaLabel="three-dots-loading"
        wrapperStyle
        wrapperClass
      />
    </div>
  );
}
