import { useState } from "react";
import ReactModal from "react-modal";
import { IoIosClose } from "react-icons/io";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
export function ImageModal({
  isModalOpen,
  onClose,
  Images,
  activeByDefault = 0,
}) {
  const [active, setactive] = useState(activeByDefault);
  const changeActive = (type) => {
    if (type === "next") {
      if (active + 1 > Images.length - 1) {
        setactive(0);
        return;
      }
      setactive(active + 1);
    } else if (type === "prev") {
      if (active - 1 < 0) {
        setactive(Images.length - 1);
        return;
      }
      setactive(active - 1);
    }
  };
  return (
    <ReactModal
      isOpen={isModalOpen}
      appElement={document.getElementById("root")}
      style={{
        overlay: {
          zIndex: 101,
          backgroundColor: "rgba(0,0,0,0.5)",
        },
      }}
      className={
        "absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[95%] h-[90%]"
      }
      onRequestClose={onClose}
    >
      <div className="bg-[rgba(0,0,0,0.8)] rounded-lg w-full h-full ">
        <div onClick={onClose} className="flex items-end justify-end">
          <IoIosClose
            size={"45px"}
            className={"text-[#fff] hover:cursor-pointer"}
          />
        </div>
        <div className="flex h-[90%]">
          <div
            className="flex items-center justify-center"
            onClick={() => {
              changeActive("prev");
            }}
          >
            <BsChevronLeft
              size={"45px"}
              className={"text-[#fff] hover:cursor-pointer"}
            />
          </div>
          <div className="flex flex-1 items-center justify-center">
            <img className="object-contain max-h-full" src={Images[active]} />
          </div>
          <div
            className="flex items-center justify-center"
            onClick={() => {
              changeActive("next");
            }}
          >
            <BsChevronRight
              size={"45px"}
              className={"text-[#fff] hover:cursor-pointer"}
            />
          </div>
        </div>
      </div>
      x
    </ReactModal>
  );
}
