import { toast } from "react-toastify";

const config = {
  position: "bottom-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  theme: "light",
};

export const Toast = (text, type = "info") => {
  if (type == "info") {
    toast.info(text, config);
  } else if (type == "success") {
    toast.success(text, config);
  } else if (type == "warn") {
    toast.warn(text, config);
  } else if (type == "error") {
    toast.error(text, config);
  }
};
