import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../utils/firebase";
import { useState, useEffect, createContext } from "react";

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setUser(user);
    });
  }, []);

  return (
    <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>
  );
};

export const AuthContext = createContext();
