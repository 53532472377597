import "./App.css";
import { HomePanel } from "./pages/user";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div className="App">
      <HomePanel />
      <ToastContainer
        hideProgressBar
        position={"bottom-center"}
        closeOnClick
        limit={2}
      />
    </div>
  );
}

export default App;
